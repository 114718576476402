import "./App.css";
import Particles, { IParticlesParams } from "react-particles-js";
import ClaudeLogo from "./assets/images/logos/claude.svg";

import * as React from "react";

const particleLogo = {
    fps_limit: 28,
    particles: {
        number: {
            value: 290,
            density: {
                enable: false,
            },
        },
        line_linked: {
            enable: true,
            distance: 50,
            opacity: 0.4,
        },
        move: {
            speed: 2,
        },
        opacity: {
            anim: {
                enable: true,
                opacity_min: 0.05,
                speed: 1,
                sync: false,
            },
            value: 0.4,
        },
    },
    polygon: {
        enable: true,
        scale: 2,
        type: "inline",
        move: {
            radius: 10,
        },
        url: ClaudeLogo,
        inline: {
            arrangement: "equidistant",
        },
        draw: {
            enable: true,
            stroke: {
                color: "rgba(255, 255, 255, .0)",
            },
        },
    },
    retina_detect: false,
    interactivity: {
        events: {
            onhover: {
                enable: true,
                mode: `repulse`,
            },
        },
        modes: {
            repulse: {
                duration: .05,
                distance: 20,
            },
        },
    },
} as IParticlesParams;

const App: React.FC = () => (
    <div
        className="particles-wrapper"
    >
        <Particles params={particleLogo} />
    </div >
);

export default App;
